<template>
	<div id="preliminary" ref="preliminary">
		<div class="nav">
			<top-vue></top-vue>
		</div>
		<div class="container" style="width:1200px;margin:40px auto;background-color:#fff;">
			<div style="text-align: left;border-bottom: 1px solid rgb(201 201 201);padding:10px 10px;">
				<p style="font-size: 20px;">
					<i style="color: #ff0000;font-style: normal;padding-right:10px;">{{ showUsername }}</i>考生：
				</p>
				<h3 style="text-indent: 2em;margin-bottom: 5px;margin-top: 5px;">
					您好！根据您的做答情况，现给出初步分析报告如下：</h3>
			</div>
			<div class="text">
				<div style="min-height: 500px;margin: 0 20px;">
					<h2>一、兴趣、能力、职业倾向</h2>
					<div style="float:left;width: 500px;height: 385px;padding: 0 25px;">
						<el-table :data="typeData" border style="width: 100%">
							<el-table-column prop="lx" label="类型">
							</el-table-column>
							<el-table-column prop="xq" label="兴趣">
							</el-table-column>
							<el-table-column prop="nl" label="能力">
							</el-table-column>
							<el-table-column prop="qx" label="职业倾向">
							</el-table-column>
						</el-table>
					</div>
					<div style="float: left;width:600px;height: 385px;">
						<span style="background:#7b68ee;padding: 0px 8px;margin-right: 5px;"></span><span
							style="margin-right: 10px;">兴趣</span>
						<span style="background:#cd3700;padding: 0px 8px;margin-right: 5px;"></span><span
							style="margin-right: 10px;">能力</span>
						<span style="background:#8b8b00;padding: 0px 8px;margin-right: 5px;"></span><span
							style="margin-right: 10px;">职业倾向</span>
						<div ref="byLine" id="byLine" style="width: 100%; height: 100%"></div>
					</div>
				</div>
				<div style="min-height: 400px;margin: 0 20px;">
					<div style="float:left;width: 500px;height: 385px;padding: 0 25px;">
						<el-table :data="scoreData" border style="width: 100%;background-color:#f2f2f2;">
							<el-table-column prop="lx" label="类型">
							</el-table-column>
							<el-table-column prop="max" label="最高分">
							</el-table-column>
							<el-table-column prop="avg" label="平均分">
							</el-table-column>
						</el-table>
					</div>
					<div style="float: left;width:600px;height: 385px;">
						<div ref="myLine" id="myLine" style="width: 100%; height: 100%"></div>
					</div>
				</div>
				<div style="min-height: 300px;margin: 0 20px;">
					<h3>得分最高的三项专业类型信息</h3>
					<div>
						<tablel-vue :tableData="topList" v-if="topList.length > 0"></tablel-vue>
					</div>
				</div>
				<div style="margin: 0 20px;">
					<h2>
						二、性格代码：{{ mbtiCode }}
						<el-button class="btn" @click="mbtiDetailDialog = true">
							查看详情
						</el-button>
					</h2>

				</div>
				<!-- <div style="margin: 0 20px;padding: 10px 0 60px;">
					<h2>三、最终分析报告</h2>
					<span style="font-size: 20px;color: #dc0000;">等待老师做专业评定，老师评定后将发短信通知您！</span>
				</div> -->
			</div>
		</div>
		<el-dialog title="MBTI性格代码详情" :visible.sync="mbtiDetailDialog" width="60%">
			<img src="@/assets/mbtiDetail.png" alt="" style="width: 100%;">
		</el-dialog>
		<div style="width: 100px;height: 100px;margin: 0 auto;">
			<el-button class="button" @click="nextClick"
				style="border: none;padding: 15px 73px;color: #fff;font-size: 20px;font-weight: bold; border-radius: 25px;">
				下一步</el-button>
		</div>
	</div>
</template>

<script>
import tablelVue from '@/components/tablel.vue';
import topVue from '@/components/top.vue';
import Cookies from "js-cookie"
import * as systemApi from "@/api/subjectSystemApi"
import {
	mapState,
} from "vuex"

export default {
	data() {
		return {
			mbtiDetailDialog: false,
			//mbti性格
			mbtiCode: '',
			//得分最高的三项专业
			topList: [],
			//兴趣、能力、职业倾向
			typeData: [{
				lx: '技术',
				syS_TestAnswerType: '1',
				xq: 0,
				nl: 0,
				qx: 0
			},
			{
				lx: '研究',
				syS_TestAnswerType: '2',
				xq: 0,
				nl: 0,
				qx: 0
			},
			{
				lx: '艺术',
				syS_TestAnswerType: '3',
				xq: 0,
				nl: 0,
				qx: 0
			},
			{
				lx: '社会',
				syS_TestAnswerType: '4',
				xq: 0,
				nl: 0,
				qx: 0
			},
			{
				lx: '经营',
				syS_TestAnswerType: '5',
				xq: 0,
				nl: 0,
				qx: 0
			},
			{
				lx: '事务',
				syS_TestAnswerType: '6',
				xq: 0,
				nl: 0,
				qx: 0
			},
			{
				lx: '自然',
				syS_TestAnswerType: '7',
				xq: 0,
				nl: 0,
				qx: 0
			},
			],
			//类型	最高分	平均分
			scoreData: [{
				lx: '技术',
				max: 11,
				avg: 0,
			},
			{
				lx: '研究',
				max: 11,
				avg: 0,
			},
			{
				lx: '艺术',
				max: 11,
				avg: 0,
			},
			{
				lx: '社会',
				max: 11,
				avg: 0,
			},
			{
				lx: '经营',
				max: 11,
				avg: 0,
			},
			{
				lx: '事务',
				max: 11,
				avg: 0,
			},
			{
				lx: '自然',
				max: 11,
				avg: 0,
			},
			],
			//柱形图
			byLine_option: {
				dataset: {
					// 提供一份数据。
					source: []
				},
				// 声明一个 X 轴，类目轴（category）。默认情况下，类目轴对应到 dataset 第一列。
				xAxis: {
					type: 'category'
				},
				// 声明一个 Y 轴，数值轴。
				yAxis: {},
				// 声明多个 bar 系列，默认情况下，每个系列会自动对应到 dataset 的每一列。
				series: [{
					type: 'bar',
					color: '#7b68ee'
				}, {
					type: 'bar',
					color: '#cd3700'
				}, {
					type: 'bar',
					color: '#8b8b00'
				}]
			},
			//雷达图
			myLine_option: {
				legend: {
					data: ['平均分']
				},
				radar: {
					// shape: 'circle',
					indicator: [{
						name: '技术',
						max: 11
					},
					{
						name: '研究',
						max: 11
					},
					{
						name: '艺术',
						max: 11
					},
					{
						name: '社会',
						max: 11
					},
					{
						name: '经营',
						max: 11
					},
					{
						name: '事务',
						max: 11
					},
					{
						name: '自然',
						max: 11
					},
					]
				},
				series: [{
					type: 'radar',
					data: []
				}]
			},
			//base64图片URL
			chartImg: {
				reportImg1: '',
				reportImg2: ''
			}
		}
	},
	computed: {
		showUsername() {
			return this.subjectData.studentName;
		},
		...mapState(["majorStatus", "subjectData"]),
	},
	components: {
		topVue,
		tablelVue,
	},
	created() {
		this.getStatus()
	},
	methods: {
		handleSelectQx(){

		},
		getStatus() {
			if (this.majorStatus == '') {
				let status = sessionStorage.getItem('majorStatus');
				this.$store.commit('setMajorStatus', status);
			}
			if (Object.keys(this.subjectData).length == 0) {
				let subjectData = JSON.parse(sessionStorage.getItem('subjectData'));
				console.log(subjectData);
				this.$store.commit('setSubjectData', subjectData);
			}
			if (this.majorStatus == '0') {
				this.$router.push('introduce');
			} else if (this.majorStatus == '1' || this.majorStatus == '2' || this.majorStatus == '3') {
				this.$router.push('mojor');
			} else if (this.majorStatus == '4') {
				this.$router.push('character');
			} else if (this.majorStatus == '6') {
				this.$router.push('supplement');
			} else if (this.majorStatus == '7') {
				this.$router.push('final');
			}
			this.getReport()
		},
		getReport() {
			let query = {
				subjectTestId: this.subjectData.id
			}
			console.log(this.subjectData);
			systemApi.getTestList(query).then(res => {
				console.log(res);
				let source = [
					['product', '兴趣', '能力', '职业倾向'],
					['技术'],
					['研究'],
					['艺术'],
					['社会'],
					['经营'],
					['事务'],
					['自然'],
				];
				res.result.map(item => {
					if (item.syS_TestQuestion == 1) {
						this.typeData[item.syS_TestAnswerType - 1].xq += item.amount
					} else if (item.syS_TestQuestion == 2) {
						this.typeData[item.syS_TestAnswerType - 1].nl += item.amount
					} else if (item.syS_TestQuestion == 3) {
						this.typeData[item.syS_TestAnswerType - 1].qx += item.amount
					}
				})
				//console.log(this.typeData);
				let avg = [0, 0, 0, 0, 0, 0, 0],
					sum = [0, 0, 0, 0, 0, 0, 0];
				//遍历取数据并将数据插入柱状图数据
				for (let i = 0; i < this.typeData.length; i++) {
					//总分
					sum[i] += (this.typeData[i].xq + this.typeData[i].nl + this.typeData[i].qx);
					//柱状图数据添加
					source[i + 1].push(this.typeData[i].xq);
					source[i + 1].push(this.typeData[i].nl);
					source[i + 1].push(this.typeData[i].qx);
				}
				this.byLine_option.dataset.source = source;
				//雷达图左边数据
				for (let i = 0; i < this.scoreData.length; i++) {
					//计算平均分
					avg[i] = sum[i] / 3;
					this.scoreData[i].avg = avg[i].toFixed(2);
				}
				//console.log(sum);
				//插入雷达图数据
				source = [{
					value: [],
					name: '平均分',
					areaStyle: {
						color: 'rgba(255, 85, 0, 0.7)'
					}
				}]
				for (let i = 0; i < this.scoreData.length; i++) {
					source[0].value.push(this.scoreData[i].avg);
				}
				this.myLine_option.series[0].data = source;
				this.initChart()

				//取最高三个
				let listQuery = {
					limit: 999,
					studentName: this.subjectData.studentName,
					subjectTestId: this.subjectData.id
				}
				systemApi.getTopInfo().then(infoRes => {
					console.log(infoRes)
					let tempList = [...this.typeData];
					console.log(tempList);
					tempList.sort((a, b) => {
						return (b.xq + b.nl + b.qx) - (a.xq + a.nl + a.qx)
					})
					for (let i = 0; i < 3; i++) {
						this.topList.push({
							lx: tempList[i].lx,
							sm: infoRes.data.find(u => {
								return u.syS_TestAnswerType == tempList[i]
									.syS_TestAnswerType
							}).introduce,
							dx: infoRes.data.find(u => {
								return u.syS_TestAnswerType == tempList[i]
									.syS_TestAnswerType
							}).classJobs,
							zy: infoRes.data.find(u => {
								return u.syS_TestAnswerType == tempList[i]
									.syS_TestAnswerType
							}).schoolSubjects,
						})
					}
				})

				//取MBTI性格
				systemApi.getMbtiList(listQuery).then(mbtiRes => {
					console.log(mbtiRes);
					let mbti = [0, 0, 0, 0, 0, 0, 0, 0];
					mbtiRes.result.map(item => {
						if (item.syS_MBTIResultType == 'E') {
							mbti[0]++
						} else if (item.syS_MBTIResultType == 'I') {
							mbti[1]++
						} else if (item.syS_MBTIResultType == 'S') {
							mbti[2]++
						} else if (item.syS_MBTIResultType == 'N') {
							mbti[3]++
						} else if (item.syS_MBTIResultType == 'T') {
							mbti[4]++
						} else if (item.syS_MBTIResultType == 'F') {
							mbti[5]++
						} else if (item.syS_MBTIResultType == 'J') {
							mbti[6]++
						} else if (item.syS_MBTIResultType == 'P') {
							mbti[7]++
						}
					})

					mbti[0] > mbti[1] ? this.mbtiCode += 'E' : this.mbtiCode += 'I';
					mbti[2] > mbti[3] ? this.mbtiCode += 'S' : this.mbtiCode += 'N';
					mbti[4] > mbti[5] ? this.mbtiCode += 'T' : this.mbtiCode += 'F';
					mbti[6] > mbti[7] ? this.mbtiCode += 'J' : this.mbtiCode += 'P';
				})

			})
		},
		initChart() {
			//let byLine = this.$echarts.init(document.getElementById('byLine'));
			let byLine = this.$echarts.init(this.$refs.byLine);
			byLine.setOption(this.byLine_option);
			//let myLine = this.$echarts.init(document.getElementById('myLine'));
			let myLine = this.$echarts.init(this.$refs.myLine);
			myLine.setOption(this.myLine_option);
			setTimeout(() => {
				this.chartImg.reportImg1 = byLine.getConnectedDataURL('png');
				this.chartImg.reportImg2 = myLine.getConnectedDataURL('png');
				//console.log(this.chartImg);
			}, 1000)
		},
		nextClick() {
			systemApi.saveReport(this.chartImg).then(res => {
				if (res.code == 200) {
					this.$nextTick(() => {
						this.$refs.preliminary.scrollIntoView();
					});
					this.$store.commit('setMajorStatus', '6');
					this.$router.push('/supplement');
				} else {
					this.$message({
						message: '提交数据失败，请重试',
						type: 'error'
					})
				}
			}).catch(() => {
				this.$message({
					message: '提交数据失败，请重试',
					type: 'error'
				})
			})
		}
	}
}
</script>

<style>
.text h2 {
	background: #9eacfd;
	color: #fff;
	border-radius: 10px;
	margin: 0;
	padding: 20px 10px;
	margin: 20px 0;
	text-align: left;
}
</style>
<style rel="stylesheet/scss" lang="scss" scoped>
#preliminary {
	.cell {
		text-align: center;
	}

	.button.el-button {
		background: #9eacfd;
	}

	.button.el-button:focus,
	.button.el-button:hover {
		background: #9eacfd !important;
	}
}
</style>
